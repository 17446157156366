table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 60%;
    margin-left: 20%;
    margin-bottom: 10px;
    border: "none";
    background: 'transparent';
  }
  
  table td, table th {
    width: 30%;
    border-bottom: 0.5px solid rgb(226, 226, 226);
    padding: 5px;
  }
  
  table tr:hover {background-color: rgb(241, 241, 241);}

  .row-selected {
    background-color: rgb(241, 241, 241);
  }
  
  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #ffffff;
    color: rgb(5, 5, 5);
  }

  .loading{
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
  }

  .container{
    padding-top:3%;
    padding-bottom: 3%;
    border-bottom: 1.5px solid  rgb(107, 107, 107);
  }

  .container-special{
    padding-top:3%;
    padding-bottom: 3%;
  }
