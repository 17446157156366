.header {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .headerlink-title {
    color: black;
    text-decoration: none;
    padding: 0 10px;
  }
  
  .headerlink-dot {
    margin-top: -0.5rem;
    opacity: 0;
    transition: opacity 200ms linear;
  }
  
  .headerlink-title:hover .headerlink-dot {
    opacity: 1;
  }
  
  .headerlink-dot-active {
    margin-top: -0.5rem;
    display: block;
  }