*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

.App {
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
}

.Navbar {
  /* background-color: #021e39; */
  width: 100%;
  height: 80px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
}

.Navbar .leftSide{
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Navbar .leftSide .links{
  max-height: 80px;
}

.Navbar .leftSide .links a{
  text-decoration: none;
  color: white;
  font-size: 25px;
  margin-left: 15px;
}

.Navbar .rightSide{
  flex: 50%;
}

.label-and-input{
  align-items: left;
}
label {
  color: #000000;
  font-weight: bold;
  width: 100%;
  float: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
